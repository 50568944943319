import { useState, useEffect } from "react";
import {
  DialogDarkBody,
  DialogDarkContent,
  DialogDarkHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { AutoComplete } from "@/components/ui/autocomplete";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { cn } from "@/lib/utils";

import { z } from "zod";
import {
  ArrowRight,
  Minus,
  Plus,
  DollarSign,
  CreditCard,
  Nut,
  Handshake,
} from "lucide-react";
import { Icons } from "@/components/brand/icons";
import { ConnectDigitalAccount } from "./connect-digital-account";
import { createDigitalAccount } from "../api";
import { institutions } from "@/config/financial-institutions";
import { AddAccountViaStatement } from "./add-account-via-statement";
import { Typeahead } from "@/components/ui/typeahead";

export function CreateFinancialAccountDialog({ onClose, reload, setProducts }) {
  const [entryType, setEntryType] = useState();
  const [step, setStep] = useState(1);
  const [institutionOpen, setInstitutionOpen] = useState(false);
  const [institutionSearch, setInstitutionSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState(null);
  const [showSecondaryUsername, setShowSecondaryUsername] = useState(false);

  const formSchema = z.object({
    account_type: z.string(),
    name: z.string().min(1, "Account name is required"),
    username: z.string(),
    secondary_username: z.string(),
    notes: z.string(),
    url: z.string(),
    accounts: z.array(
      z.object({
        name: z.string(),
        balance: z
          .number()
          .or(z.string().regex(/^\d+(\.\d{1,2})?$/, "Invalid balance")),
        account_type: z.string().optional(),
      })
    ),
  });
  const defaultValues = {
    account_type: "financial",
    name: "",
    username: "",
    secondary_username: "",
    url: "",
    notes: "",
    accounts: [{ name: "", balance: "0.00", account_type: "" }],
  };

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
    reset,
    watch,
    ...rest
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  async function onSubmit(values) {
    if (values.account_type === "financial" && !values.name) {
      setError("name", {
        type: "manual",
        message: "Financial institution is required",
      });
      return;
    }
    setLoading(true);
    createDigitalAccount(values)
      .then((res) => {
        setAccount(res.data);
        setStep(2);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  const financial_institution = watch("name");
  const accounts = watch("accounts");
  const account_type = watch("account_type");

  useEffect(() => {
    if (financial_institution) {
      const institution = institutions.find(
        (i) => i.value === financial_institution
      );
      if (institution) {
        setValue("url", institution.url);
      }
    }
  }, [financial_institution]);

  return (
    <>
      {step === 1 && (
        <DialogDarkContent className="sm:max-w-[600px] overflow-y-auto h-full sm:h-auto max-h-screen">
          <DialogDarkHeader>
            <DialogTitle>Add a financial account</DialogTitle>
            <DialogDescription className="sr-only">
              Add account details to your tapestry.
            </DialogDescription>
          </DialogDarkHeader>
          <DialogDarkBody className="flex-grow overflow-y-auto">
            <div
              className={cn(
                "flex flex-col  py-4 items-center mb-8",
                entryType ? "gap-4" : "gap-12"
              )}
            >
              <div
                className={cn(
                  "flex flex-col items-center gap-2",
                  entryType && "transform scale-90 -translate-y-4"
                )}
              >
                <h2 className="text-2xl font-bold tracking-tight">
                  Select a way to add your account
                </h2>
                <p className="text-md text-muted-foreground">
                  Accounts can be manually entered or connected through Plaid.
                </p>
              </div>
              <div className="flex gap-4 flex-col md:flex-row">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <button
                      className={cn(
                        "transition-all duration-500 ease-in-out w-[150px] flex flex-col items-center rounded-xl border bg-card p-4 text-card-foreground shadow transition-colors hover:bg-muted/50",
                        entryType === "plaid" && "bg-muted"
                      )}
                    >
                      <img
                        src="/emoji/counterclockwise-arrows-button.png"
                        alt="Thumbs Up"
                        className="h-10 w-10"
                      />
                      <span className="font-medium mt-2">
                        Directly sync account
                      </span>
                    </button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="start" className="w-full">
                    <DropdownMenuLabel>
                      Type of Financial Account
                    </DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    <DropdownMenuGroup>
                      <DropdownMenuItem
                        onClick={() => {
                          setProducts(["transactions"]);
                          onClose();
                        }}
                      >
                        <CreditCard className="mr-2 w-5 h-5" />
                        <span>Credit Cards, Checking, & Savings</span>
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => {
                          setProducts(["investments"]);
                          onClose();
                        }}
                      >
                        <Nut className="mr-2 w-5 h-5" />
                        <span>Investments</span>
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={() => {
                          setProducts(["liabilities"]);
                          onClose();
                        }}
                      >
                        <Handshake className="mr-2 w-5 h-5" />
                        <span>Loan</span>
                      </DropdownMenuItem>
                    </DropdownMenuGroup>
                  </DropdownMenuContent>
                </DropdownMenu>

                <button
                  onClick={() => {
                    setEntryType("statement");
                  }}
                  className={cn(
                    "transition-all duration-500 ease-in-out w-[150px] flex flex-col items-center rounded-xl border bg-card p-4 text-card-foreground shadow transition-colors hover:bg-muted/50",
                    entryType === "statement" && "bg-muted"
                  )}
                >
                  <img
                    src="/emoji/document.png"
                    alt="Thumbs Up"
                    className="h-10 w-10"
                  />
                  <span className="font-medium mt-2">Add via statement</span>
                </button>
                <button
                  onClick={() => {
                    if (entryType === "manual") {
                      setEntryType(null);
                    } else {
                      setEntryType("manual");
                    }
                  }}
                  className={cn(
                    "transition-all duration-500 ease-in-out w-[150px] flex flex-col items-center rounded-xl border bg-card p-4 text-card-foreground shadow transition-colors hover:bg-muted/50",
                    entryType === "manual" && "bg-muted"
                  )}
                >
                  <img
                    src="/emoji/pencil.png"
                    alt="Thumbs Up"
                    className="h-10 w-10"
                  />
                  <span className="font-medium mt-2">
                    Manually enter details
                  </span>
                </button>
              </div>
            </div>

            {entryType === "statement" && (
              <AddAccountViaStatement
                setAccount={setAccount}
                setStep={setStep}
              />
            )}

            {entryType === "manual" && (
              <Form {...rest} control={control}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="flex flex-col gap-4 pb-4">
                    {account_type !== "financial" && (
                      <FormField
                        control={control}
                        name="name"
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <div className="space-y-2">
                                <Label htmlFor="name">Name</Label>
                                <Input placeholder="" id="name" {...field} />
                              </div>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    )}
                    {account_type === "financial" && (
                      <FormField
                        control={control}
                        name="name"
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <div className="space-y-2 flex flex-col gap-2">
                                <Label htmlFor="name">
                                  Financial Institution
                                </Label>
                                <Typeahead
                                  options={institutions}
                                  emptyMessage="No results."
                                  placeholder="Input Institution"
                                  {...field}
                                  onValueChange={(value) => {
                                    field.onChange(value.value);
                                  }}
                                />
                              </div>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    )}
                    <FormField
                      control={control}
                      name="url"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <div className="space-y-2">
                              <Label htmlFor="url">Website</Label>
                              <Input id="url" {...field} />
                            </div>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={control}
                      name="username"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <div className="space-y-2">
                              <div className="flex justify-between items-center gap-2">
                                <Label htmlFor="username">
                                  Username / Email
                                </Label>
                                {!showSecondaryUsername && (
                                  <Button
                                    className="rounded-full"
                                    type="button"
                                    variant="ghost"
                                    onClick={() =>
                                      setShowSecondaryUsername(
                                        !showSecondaryUsername
                                      )
                                    }
                                  >
                                    <Plus className="w-3 h-3 mr-2" />{" "}
                                    <span className="text-sm">
                                      Add Secondary
                                    </span>
                                  </Button>
                                )}
                              </div>
                              <Input id="username" placeholder="" {...field} />
                            </div>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    {showSecondaryUsername && (
                      <FormField
                        control={control}
                        name="secondary_username"
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <div className="flex justify-between gap-2">
                                <Input
                                  id="secondary_username"
                                  placeholder="Secondary Username"
                                  {...field}
                                />
                                <Button
                                  onClick={() => {
                                    setShowSecondaryUsername(false);
                                  }}
                                  type="button"
                                  variant="ghost"
                                  size="icon"
                                >
                                  <Minus className="w-4 h-4" />
                                </Button>
                              </div>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    )}
                    {account_type === "financial" && (
                      <FormField
                        control={control}
                        name="accounts"
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <div className="space-y-2">
                                <div className="flex justify-between items-center gap-2">
                                  <Label htmlFor="accounts">Accounts</Label>
                                  <Button
                                    className="rounded-full"
                                    type="button"
                                    variant="ghost"
                                    onClick={() => {
                                      setValue("accounts", [
                                        ...accounts,
                                        {
                                          name: "",
                                          balance: 0,
                                          account_type: "",
                                        },
                                      ]);
                                    }}
                                  >
                                    <Plus className="w-3 h-3 mr-2" />
                                    <span className="text-sm">Add Another</span>
                                  </Button>
                                </div>
                                {accounts.map((_, index) => (
                                  <div
                                    className="flex justify-between gap-2"
                                    key={index}
                                  >
                                    <Input
                                      className="flex-1"
                                      placeholder="Checking"
                                      id="accounts"
                                      onChange={(e) => {
                                        setValue(
                                          "accounts",
                                          accounts.map((a, i) =>
                                            i === index
                                              ? { ...a, name: e.target.value }
                                              : a
                                          )
                                        );
                                      }}
                                      value={accounts[index].name}
                                    />

                                    <Select
                                      value={accounts[index].account_type}
                                      onValueChange={(value) => {
                                        setValue(
                                          "accounts",
                                          accounts.map((a, i) =>
                                            i === index
                                              ? {
                                                  ...a,
                                                  account_type: value,
                                                }
                                              : a
                                          )
                                        );
                                      }}
                                    >
                                      <SelectTrigger className="w-[130px]">
                                        <SelectValue placeholder="Account Type" />
                                      </SelectTrigger>
                                      <SelectContent>
                                        <SelectGroup>
                                          <SelectLabel>
                                            Account Types
                                          </SelectLabel>
                                          <SelectItem value="depository">
                                            Checking / Savings
                                          </SelectItem>
                                          <SelectItem value="credit">
                                            Credit Card
                                          </SelectItem>
                                          <SelectItem value="loan">
                                            Loan
                                          </SelectItem>
                                          <SelectItem value="investment">
                                            Investment
                                          </SelectItem>
                                          <SelectItem value="other">
                                            Other
                                          </SelectItem>
                                        </SelectGroup>
                                      </SelectContent>
                                    </Select>

                                    <div className="relative w-1/5">
                                      <DollarSign className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-500" />
                                      <Input
                                        id="balance"
                                        type="number"
                                        placeholder="0.00"
                                        step="1"
                                        value={accounts[index].balance}
                                        onChange={(e) => {
                                          setValue(
                                            "accounts",
                                            accounts.map((a, i) =>
                                              i === index
                                                ? {
                                                    ...a,
                                                    balance: e.target.value,
                                                  }
                                                : a
                                            )
                                          );
                                        }}
                                        className="pl-9"
                                      />
                                    </div>
                                    <Button variant="ghost" size="icon">
                                      <Minus
                                        type="button"
                                        className="w-4 h-4"
                                        onClick={() => {
                                          setValue(
                                            "accounts",
                                            accounts.filter(
                                              (a, i) => i !== index
                                            )
                                          );
                                        }}
                                      />
                                    </Button>
                                  </div>
                                ))}
                              </div>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    )}
                    <FormField
                      control={control}
                      name="notes"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <div className="space-y-2">
                              <Label htmlFor="notes">Notes</Label>
                              <Textarea
                                id="notes"
                                placeholder="Add any additional context to this account."
                                {...field}
                              />
                            </div>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <DialogFooter>
                    <Button type="submit" disabled={loading}>
                      {loading ? (
                        <Icons.spinner className="h-4 w-4 animate-spin mr-2" />
                      ) : (
                        <ArrowRight className="w-4 h-4 mr-2" />
                      )}
                      Next
                    </Button>
                  </DialogFooter>
                </form>
              </Form>
            )}
          </DialogDarkBody>
        </DialogDarkContent>
      )}
      {step === 2 && (
        <ConnectDigitalAccount
          account={account}
          onClose={onClose}
          reload={reload}
        />
      )}
    </>
  );
}
