import { Button } from "@/components/ui/button";
import { MonitorPlay } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";

export function AddPersonVideo({ small = false }) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        {small ? (
          <Button
            className="ml-4 rounded-full text-xs h-8 bg-transparent"
            variant="outline"
          >
            <MonitorPlay className="w-4 h-4 mr-2" />
            Learn More
          </Button>
        ) : (
          <Button variant="outline">
            <MonitorPlay className="w-4 h-4 mr-2" />
            {"Watch our Walkthrough"}
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="w-full !max-w-2xl">
        <DialogHeader>
          <DialogTitle>Learn more about People</DialogTitle>
          <DialogDescription className="sr-only">
            Video Walkthrough of working with people.
          </DialogDescription>
        </DialogHeader>
        <div className="flex items-center justify-center">
          <div className="size-full border-2 border-white rounded-2xl overflow-hidden isolate z-[1] relative">
            <iframe
              src={
                "https://www.loom.com/embed/1614339950fa4994abb01ce597f7a50e?sid=6bc283be-3c26-4ba5-aae6-839c9999dc9e"
              }
              className="size-full rounded-2xl min-h-[400px]"
              allowFullScreen
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
