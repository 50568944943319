import { useEffect, useState } from "react";
import {
  DialogDarkContent,
  DialogDarkTitle,
  DialogDarkHeader,
  DialogDescription,
  DialogDarkBody,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { FlagOff, Plus, Tag, X } from "lucide-react";
import { getLabels, manageLabels } from "../api";
import { Separator } from "@/components/ui/separator";
import { Icons } from "@/components/brand/icons";

export function CreateLabelDialog({ reload, onClose }) {
  const [labels, setLabels] = useState([]);
  const [labelName, setLabelName] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getLabels().then((res) => {
      setLabels(res.data);
    });
  }, []);
  return (
    <DialogDarkContent>
      <DialogDarkHeader>
        <DialogDarkTitle>Manage Labels</DialogDarkTitle>
        <DialogDescription className="sr-only">
          Add a label to your tapestry.
        </DialogDescription>
      </DialogDarkHeader>
      <DialogDarkBody>
        <div className="flex gap-1  items-center">
          <span className="font-sans font-medium text-sm">Add New Label</span>
        </div>
        <div className="flex items-center w-full mt-2 gap-2">
          <Input
            onChange={(e) => setLabelName(e.target.value)}
            value={labelName}
          />
          <Button
            variant="ghost"
            onClick={() => {
              setLabels([
                ...labels,
                { name: labelName, id: labelName, status: "active" },
              ]);
              setLabelName("");
            }}
          >
            <Plus className="w-4 h-4 mr-2" /> Add
          </Button>
        </div>
        <div className="flex gap-1 items-center mt-8">
          <Tag className="w-4 h-4 text-muted-foreground" />
          <span className="font-sans font-medium text-sm">Active Labels</span>
        </div>
        <div className="flex mt-4 gap-2 flex-wrap">
          {labels
            .filter((label) => label.status === "active")
            .map((label) => (
              <div
                className="bg-gray-200 px-2 py-1.5 text-sm rounded-sm flex items-center gap-2"
                key={label.id}
              >
                {label.name}
                <Button
                  onClick={() => {
                    if (label.id === label.name) {
                      setLabels(labels.filter((l) => l.id !== label.id));
                      return;
                    }
                    setLabels(
                      labels.map((l) =>
                        l.id === label.id ? { ...l, status: "inactive" } : l
                      )
                    );
                  }}
                  className="rounded-full w-4 h-4"
                  variant="ghost"
                  size="icon"
                >
                  <X className="w-3 h-3" />
                </Button>
              </div>
            ))}
        </div>

        <Separator className="mt-8" />

        <div className="flex gap-1 items-center mt-8">
          <FlagOff className="w-4 h-4 text-muted-foreground" />
          <span className="font-sans font-medium text-sm">Disabled Labels</span>
        </div>
        <div className="flex mt-4 gap-2 flex-wrap">
          {labels
            .filter((label) => label.status === "inactive")
            .map((label) => (
              <Button
                onClick={() => {
                  setLabels(
                    labels.map((l) =>
                      l.id === label.id ? { ...l, status: "active" } : l
                    )
                  );
                }}
                className="bg-gray-100 opacity-50 hover:opacity-100 px-2 py-1.5 text-sm rounded-sm flex items-center gap-2"
                key={label.id}
                variant="ghost"
              >
                {label.name}
                <Plus className="w-3 h-3" />
              </Button>
            ))}
        </div>

        <DialogFooter className="mt-8">
          <Button
            disabled={loading}
            onClick={() => {
              setLoading(true);
              manageLabels({ labels })
                .then((res) => {
                  onClose();
                  reload();
                })
                .finally(() => {
                  setLoading(false);
                });
            }}
          >
            {loading && <Icons.spinner className="animate-spin w-4 h-4 mr-2" />}
            Save Changes
          </Button>
        </DialogFooter>
      </DialogDarkBody>
    </DialogDarkContent>
  );
}
