import { useState } from "react";
import {
  DialogDarkContent,
  DialogDarkBody,
  DialogDescription,
  DialogFooter,
  DialogDarkHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { z } from "zod";
import { ArrowRight } from "lucide-react";
import { Icons } from "@/components/brand/icons";
import { ConnectPerson } from "./connect-person";
import { createPerson } from "../api";
import { PhoneInput } from "@/components/ui/phone-input";
import { Checkbox } from "@/components/ui/checkbox";
import { AddPersonVideo } from "@/components/help/add-person-video";

export function CreatePersonDialog({ onClose, reload }) {
  const [step, setStep] = useState(1);
  const [person, setPerson] = useState(null);
  const [loading, setLoading] = useState(false);
  const formSchema = z.object({
    name: z.string().min(1, "Person name is required"),
    email: z.string().email().or(z.literal("")),
    notes: z.string(),
    phone: z
      .string()
      .refine(
        (value) => /^.{12}$/.test(value) || value === "",
        "Invalid phone number."
      ),
    role: z.string(),
    invite: z.boolean(),
  });

  const defaultValues = {
    name: "",
    email: "",
    phone: "",
    role: "",
    notes: "",
    invite: false,
  };

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
    watch,
    ...rest
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  async function onSubmit(values) {
    setLoading(true);
    try {
      const { data } = await createPerson(values);
      setPerson(data);
      setStep(2);
    } catch (error) {}
  }

  const inviteValue = watch("invite");
  const emailValue = watch("email");

  return (
    <>
      {step === 1 && (
        <DialogDarkContent className="sm:max-w-[425px]">
          <DialogDarkHeader>
            <DialogTitle className="flex items-center gap-2">
              Add a person <AddPersonVideo small />
            </DialogTitle>
            <DialogDescription className="sr-only">
              Add person details to your tapestry.
            </DialogDescription>
          </DialogDarkHeader>
          <DialogDarkBody>
            <Form {...rest} control={control}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col gap-4 pb-4">
                  <FormField
                    control={control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div className="space-y-2">
                            <Label htmlFor="name">Name</Label>
                            <Input id="name" {...field} />
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div className="space-y-2">
                            <Label htmlFor="email">Email Address</Label>
                            <Input type="email" placeholder="" {...field} />
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <div className="flex justify-between gap-4">
                    <FormField
                      control={control}
                      name="phone"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <div className="space-y-2">
                              <Label htmlFor="phone">Phone Number</Label>
                              <PhoneInput
                                type="tel"
                                placeholder="Phone Number"
                                {...field}
                                onChange={(e) => field.onChange(e)}
                              />
                            </div>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={control}
                      name="role"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <div className="space-y-2">
                              <Label htmlFor="role">Role</Label>
                              <Input
                                id="role"
                                placeholder="Lawyer, CPA, etc."
                                {...field}
                              />
                            </div>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <FormField
                    control={control}
                    name="invite"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div className="flex gap-2 items-center mt-2">
                            <Checkbox
                              {...field}
                              checked={field.value}
                              onCheckedChange={(checked) =>
                                field.onChange(checked)
                              }
                            />
                            <Label htmlFor="notes">
                              Invite Person to Tapestry
                            </Label>
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={control}
                    name="notes"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div className="space-y-2">
                            <Label htmlFor="notes">Notes</Label>
                            <Textarea
                              id="notes"
                              placeholder="Add any additional context to this person."
                              {...field}
                            />
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <DialogFooter>
                  <Button
                    type="submit"
                    disabled={loading || (inviteValue && !emailValue)}
                  >
                    {loading ? (
                      <Icons.spinner className="h-4 w-4 animate-spin mr-2" />
                    ) : (
                      <ArrowRight className="w-4 h-4 mr-2" />
                    )}
                    Next
                  </Button>
                </DialogFooter>
              </form>
            </Form>
          </DialogDarkBody>
        </DialogDarkContent>
      )}
      {step === 2 && (
        <ConnectPerson person={person} onClose={onClose} reload={reload} />
      )}
    </>
  );
}
