import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";
export function ConnectionTag({
  name,
  confidence_score: confidence,
  type,
  id,
  removeConnection,
}) {
  return (
    <div
      className={cn(
        "rounded-lg bg-[#e1b649] bg-opacity-70 border border-[#e1b649] py-1 px-2 flex gap-2 items-center",
        "bg-opacity-80"
      )}
    >
      <span className="text-gray-700 text-sm font-medium">{name}</span>
      <Button
        onClick={() => removeConnection({ id, type })}
        variant="ghost"
        size="icon"
        className="rounded-full w-6 h-6 hover:bg-white/[.5]"
      >
        <X className="w-4 h-4 text-muted-foreground" />
      </Button>
    </div>
  );
}

function generateStyleFromConfidence(confidence) {
  if (!confidence) return "bg-opacity-80";
  if (confidence > 90) return "bg-opacity-80";
  if (confidence > 80) return "bg-opacity-70";
  if (confidence > 70) return "bg-opacity-50";
  return "bg-opacity-30";
}
