import { useState, useEffect } from "react";
import {
  DialogDarkBody,
  DialogDarkContent,
  DialogDarkHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";

import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectValue,
  SelectItem,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { z } from "zod";
import { ArrowRight, Minus, Plus } from "lucide-react";
import { Icons } from "@/components/brand/icons";
import { ConnectDigitalAccount } from "./connect-digital-account";
import { createDigitalAccount } from "../api";
import { institutions } from "@/config/financial-institutions";
import { useUser } from "@/components/providers/user-provider";

export function CreateDigitalAccountDialog({ onClose, reload }) {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState(null);
  const [showSecondaryUsername, setShowSecondaryUsername] = useState(false);
  const { user } = useUser();

  const formSchema = z.object({
    account_type: z.string(),
    name: z.string().min(1, "Account name is required"),
    username: z.string(),
    secondary_username: z.string(),
    notes: z.string(),
    url: z.string(),
    accounts: z.array(
      z.object({
        name: z.string(),
        balance: z
          .number()
          .or(z.string().regex(/^\d+(\.\d{1,2})?$/, "Invalid balance")),
      })
    ),
  });
  const defaultValues = {
    account_type: "social",
    name: "",
    username: "",
    secondary_username: "",
    url: "",
    notes: "",
    accounts: [{ name: "", balance: "0.00" }],
  };

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
    reset,
    watch,
    ...rest
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  async function onSubmit(values) {
    if (values.account_type === "financial" && !values.name) {
      setError("name", {
        type: "manual",
        message: "Financial institution is required",
      });
      return;
    }
    setLoading(true);
    createDigitalAccount(values)
      .then((res) => {
        setAccount(res.data);
        setStep(2);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  const financial_institution = watch("name");
  const accounts = watch("accounts");
  const account_type = watch("account_type");

  useEffect(() => {
    if (financial_institution) {
      const institution = institutions.find(
        (i) => i.value === financial_institution
      );
      if (institution) {
        setValue("url", institution.url);
      }
    }
  }, [financial_institution]);

  return (
    <>
      {step === 1 && (
        <DialogDarkContent className="sm:max-w-[425px] overflow-y-auto h-full sm:h-auto">
          <DialogDarkHeader>
            <DialogTitle>Add a digital account</DialogTitle>
            <DialogDescription className="sr-only">
              Add account details to your tapestry.
            </DialogDescription>
          </DialogDarkHeader>
          <DialogDarkBody className="flex-grow overflow-y-auto">
            <Form {...rest} control={control}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col gap-4 pb-4">
                  <FormField
                    control={control}
                    name="account_type"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div className="space-y-2">
                            <Label htmlFor="account_type">Type</Label>
                            <Select
                              defaultValue={field.value}
                              onValueChange={field.onChange}
                            >
                              <SelectTrigger>
                                <SelectValue
                                  id="account_type"
                                  placeholder="Select account type"
                                />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="social">Social</SelectItem>
                                <SelectItem value="subscription">
                                  Subscription
                                </SelectItem>

                                <SelectItem value="other">Other</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  {account_type !== "financial" && (
                    <FormField
                      control={control}
                      name="name"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <div className="space-y-2">
                              <Label htmlFor="name">Name</Label>
                              <Input placeholder="" id="name" {...field} />
                            </div>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  )}

                  <FormField
                    control={control}
                    name="url"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div className="space-y-2">
                            <Label htmlFor="url">Website</Label>
                            <Input id="url" {...field} />
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="username"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div className="space-y-2">
                            <div className="flex justify-start items-center gap-2">
                              <Label htmlFor="username">Username / Email</Label>
                              {!showSecondaryUsername && (
                                <Button
                                  type="button"
                                  size="icon"
                                  variant="ghost"
                                  className="w-4 h-4"
                                  onClick={() =>
                                    setShowSecondaryUsername(
                                      !showSecondaryUsername
                                    )
                                  }
                                >
                                  <Plus className="w-3 h-3" />
                                </Button>
                              )}
                            </div>
                            <Input id="username" placeholder="" {...field} />
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  {showSecondaryUsername && (
                    <FormField
                      control={control}
                      name="secondary_username"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <div className="flex justify-between gap-2">
                              <Input
                                id="secondary_username"
                                placeholder="Secondary Username"
                                {...field}
                              />
                              <Button
                                onClick={() => {
                                  setShowSecondaryUsername(false);
                                }}
                                type="button"
                                variant="ghost"
                                size="icon"
                              >
                                <Minus className="w-4 h-4" />
                              </Button>
                            </div>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  )}

                  <FormField
                    control={control}
                    name="notes"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div className="space-y-2">
                            <Label htmlFor="notes">Notes</Label>
                            <Textarea
                              id="notes"
                              placeholder="Add any additional context to this account."
                              {...field}
                            />
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <DialogFooter>
                  <Button type="submit" disabled={loading}>
                    {loading ? (
                      <Icons.spinner className="h-4 w-4 animate-spin mr-2" />
                    ) : (
                      <ArrowRight className="w-4 h-4 mr-2" />
                    )}
                    Next
                  </Button>
                </DialogFooter>
              </form>
            </Form>
          </DialogDarkBody>
        </DialogDarkContent>
      )}
      {step === 2 && (
        <ConnectDigitalAccount
          account={account}
          onClose={onClose}
          reload={reload}
        />
      )}
    </>
  );
}
