import { useState } from "react";
import {
  DialogDarkContent,
  DialogDarkBody,
  DialogDescription,
  DialogFooter,
  DialogDarkHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectValue,
  SelectItem,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { z } from "zod";
import { ArrowRight, ChevronDown, ChevronUp, DollarSign } from "lucide-react";
import { Icons } from "@/components/brand/icons";
import { StatePicker } from "@/components/ui/state-picker";
import { ConnectProperty } from "./connect-property";
import { createProperty } from "../api";
import { toast } from "sonner";
import { AddPropertyVideo } from "@/components/help/add-property-video";

export function CreatePropertyDialog({ onClose, reload }) {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [property, setProperty] = useState(null);

  const formSchema = z.object({
    name: z.string().min(1, "Property name is required"),
    type: z.string().min(1, "Type is required"),
    notes: z.string(),
    address: z.string(),
    city: z.string(),
    state: z.string(),
    zipcode: z.string(),
    value: z.string(),
    make: z.string(),
    model: z.string(),
    year: z.string(),
    color: z.string(),
    license_plate: z.string(),
  });

  const defaultValues = {
    name: "",
    type: "",
    notes: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    value: "",
    make: "",
    model: "",
    year: "",
    color: "",
    license_plate: "",
  };

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
    watch,
    ...rest
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  async function onSubmit(values) {
    setLoading(true);
    createProperty(values)
      .then((res) => {
        setProperty(res.data);
        setStep(2);
      })
      .catch((err) => {
        toast.error(err.response.data.errors[0]);
        setLoading(false);
      });
  }

  const type = watch("type");

  return (
    <>
      {step === 1 && (
        <DialogDarkContent className="sm:max-w-[600px] max-h-screen overflow-auto overflow-y-auto h-full sm:h-auto">
          <DialogDarkHeader>
            <DialogTitle className="flex items-center gap-2">
              Add Property <AddPropertyVideo small />
            </DialogTitle>
            <DialogDescription className="sr-only">
              Add a property to your vault.
            </DialogDescription>
          </DialogDarkHeader>
          <DialogDarkBody>
            <Form {...rest} control={control}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col gap-3 pb-2">
                  <FormField
                    control={control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div className="space-y-2">
                            <Label htmlFor="name">Name</Label>
                            <Input id="name" {...field} />
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={control}
                    name="type"
                    render={({ field }) => {
                      return (
                        <div className="space-y-2">
                          <Label htmlFor="type">Type</Label>
                          <FormItem>
                            <FormControl>
                              <Select
                                defaultValue={field.value}
                                onValueChange={field.onChange}
                              >
                                <SelectTrigger>
                                  <SelectValue
                                    id="type"
                                    placeholder="Select property type"
                                  />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectItem value="residence">
                                    Residence
                                  </SelectItem>
                                  <SelectItem value="vehicle">
                                    Vehicle
                                  </SelectItem>
                                  <SelectItem value="valuable">
                                    Valuable
                                  </SelectItem>
                                  <SelectItem value="other">Other</SelectItem>
                                </SelectContent>
                              </Select>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        </div>
                      );
                    }}
                  />

                  <FormField
                    control={control}
                    name="value"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div className="space-y-2">
                            <Label htmlFor="property_value">
                              Estimated Value
                            </Label>
                            <div className="relative">
                              <DollarSign className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-500" />
                              <Input
                                id="property_value"
                                type="number"
                                step="1"
                                {...field}
                                className="pl-9"
                              />
                            </div>
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="notes"
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div className="space-y-2">
                            <Label htmlFor="notes">Notes</Label>
                            <Textarea id="notes" {...field} />
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  {type === "residence" && (
                    <>
                      <FormField
                        control={control}
                        name="address"
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <div className="space-y-2">
                                <Label htmlFor="address">Address</Label>
                                <Input id="address" {...field} />
                              </div>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={control}
                        name="city"
                        render={({ field }) => (
                          <FormItem>
                            <FormControl>
                              <div className="space-y-2">
                                <Label htmlFor="city">City</Label>
                                <Input id="city" {...field} />
                              </div>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <div className="flex justify-between gap-6 items-center">
                        <FormField
                          control={control}
                          name="state"
                          render={({ field }) => (
                            <FormItem className="w-full">
                              <FormControl>
                                <div className="space-y-2 flex flex-col">
                                  <Label className="my-1" htmlFor="state">
                                    State
                                  </Label>
                                  <StatePicker id="state" {...field} />
                                </div>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />

                        <FormField
                          control={control}
                          name="zipcode"
                          render={({ field }) => (
                            <FormItem className="w-full">
                              <FormControl>
                                <div className="space-y-2">
                                  <Label htmlFor="zipcode">Zipcode</Label>
                                  <Input id="zipcode" {...field} />
                                </div>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                    </>
                  )}

                  {type === "vehicle" && (
                    <>
                      <div className="flex justify-between gap-4">
                        <FormField
                          control={control}
                          name="make"
                          render={({ field }) => (
                            <FormItem className="w-full">
                              <FormControl>
                                <div className="space-y-2">
                                  <Label htmlFor="make">Make</Label>
                                  <Input id="make" {...field} />
                                </div>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={control}
                          name="model"
                          render={({ field }) => (
                            <FormItem className="w-full">
                              <FormControl>
                                <div className="space-y-2">
                                  <Label htmlFor="model">Model</Label>
                                  <Input id="model" {...field} />
                                </div>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                      <div className="flex justify-between gap-4">
                        <FormField
                          control={control}
                          name="year"
                          render={({ field }) => (
                            <FormItem className="w-full">
                              <FormControl>
                                <div className="space-y-2">
                                  <Label htmlFor="year">Year</Label>
                                  <Input id="year" {...field} />
                                </div>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={control}
                          name="color"
                          render={({ field }) => (
                            <FormItem className="w-full">
                              <FormControl>
                                <div className="space-y-2">
                                  <Label htmlFor="color">Color</Label>
                                  <Input id="color" {...field} />
                                </div>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                      <FormField
                        control={control}
                        name="license_plate"
                        render={({ field }) => (
                          <FormItem className="w-full">
                            <FormControl>
                              <div className="space-y-2">
                                <Label htmlFor="license_plate">
                                  License Plate
                                </Label>
                                <Input id="license_plate" {...field} />
                              </div>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </>
                  )}

                  <DialogFooter className="mt-4">
                    <Button type="submit" disabled={loading}>
                      {loading ? (
                        <Icons.spinner className="h-4 w-4 animate-spin mr-2" />
                      ) : (
                        <ArrowRight className="w-4 h-4 mr-2" />
                      )}
                      Next
                    </Button>
                  </DialogFooter>
                </div>
              </form>
            </Form>
          </DialogDarkBody>
        </DialogDarkContent>
      )}
      {step === 2 && (
        <ConnectProperty
          reload={reload}
          onClose={onClose}
          property={property}
        />
      )}
    </>
  );
}
