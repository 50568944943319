import { useEffect, useState } from "react";
import {
  DialogDarkContent,
  DialogDarkBody,
  DialogDescription,
  DialogFooter,
  DialogDarkHeader,
  DialogDarkTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";

import {
  fetchDocWithRecommendations,
  createConnections,
  updateDocument,
} from "../api";
import {
  NotebookPen,
  Waypoints,
  PlusIcon,
  Pencil,
  Check,
  Undo2,
  Sparkles,
} from "lucide-react";
import { AddConnectionDialog } from "./add-connection-dialog";
import { ConnectionTag } from "./connection-tag";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";

export function DocumentLinking({ document, onClose, reload, context }) {
  const [name, setName] = useState("");
  const [notes, setNotes] = useState(document.notes);
  const [editingName, setEditingName] = useState(false);
  const [editingNotes, setEditingNotes] = useState(false);
  const [recommendations, setRecommendations] = useState();
  const [connections, setConnections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [savingDocument, setSavingDocument] = useState(false);
  const [addConnectionModalOpen, setAddConnectionModalOpen] = useState(false);

  function removeConnection({ id, type }) {
    setConnections(
      connections.filter((c) => !(c.id === id && c.type === type))
    );
  }

  function saveConnections(connections) {
    setSavingDocument(true);
    updateDocument({
      id: document.id,
      data: {
        name: name || document.name,
        notes,
        connections: connections.map(({ id, type }) => ({ id, type })),
      },
    }).then((res) => {
      setSavingDocument(false);
      reload();
      onClose();
    });
  }

  useEffect(() => {
    fetchDocWithRecommendations(document.id, context)
      .then((res) => {
        setRecommendations(res.data);
        setConnections(
          res.data.recommended_items.map(
            ({ type, id, name, confidence_score }) => ({
              type,
              id,
              name,
              confidence_score,
            })
          )
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <DialogDarkContent className="sm:max-w-[425px]">
        <DialogDarkHeader>
          <DialogDarkTitle className="sr-only">
            {name || document.name}
          </DialogDarkTitle>
          <DialogDescription className="sr-only">
            Add details to your document.
          </DialogDescription>
        </DialogDarkHeader>
        <DialogDarkBody>
          <Skeleton className="h-4 w-1/2" />
        </DialogDarkBody>
      </DialogDarkContent>
    );
  }

  return (
    <>
      <DialogDarkContent className="sm:max-w-[425px]">
        <DialogDarkHeader>
          <DialogDarkTitle className="truncate items-center flex gap-2">
            {editingName ? (
              <Input
                onChange={(e) => setName(e.target.value)}
                className="w-[60%]"
                value={name}
                placeholder={recommendations?.recommended_name || name}
              />
            ) : (
              <span>{name || document.name}</span>
            )}

            {!editingName && (
              <Button
                onClick={() => setEditingName(true)}
                size="icon"
                variant="ghost"
                className="hover:bg-white/[.2]"
              >
                <Pencil className="w-4 h-4" />
              </Button>
            )}
            {editingName && (
              <div className="flex gap-1">
                <Button
                  onClick={() => {
                    setName(recommendations?.recommended_name || name);
                    setEditingName(false);
                  }}
                  size="icon"
                  variant="ghost"
                  className="hover:bg-white/[.2]"
                >
                  <Check className="w-4 h-4" />
                </Button>
                <Button
                  onClick={() => {
                    setName("");
                    setEditingName(false);
                  }}
                  size="icon"
                  variant="ghost"
                  className="hover:bg-white/[.2]"
                >
                  <Undo2 className="w-4 h-4" />
                </Button>
              </div>
            )}
          </DialogDarkTitle>
          <DialogDescription
            className={cn(
              "text-s opacity-75 flex items-center gap-1",
              !editingName || !recommendations?.recommended_name
                ? "sr-only"
                : ""
            )}
          >
            <Sparkles className="w-4 h-4" /> Tapestry Suggested Name
          </DialogDescription>
        </DialogDarkHeader>
        <DialogDarkBody>
          <div className="flex flex-col gap-6 items-center">
            <div className="rounded-lg border border=[#e6e6e6] w-full px-4 pt-2 pb-4 min-h-[130px]">
              <div className="flex gap-2 items-center justify-between w-full">
                <div className="flex gap-2 items-center">
                  <NotebookPen className="w-4 h-4" />
                  <span className="font-medium">Notes</span>
                </div>
                {editingNotes ? (
                  <div className="flex gap-1">
                    <Button
                      onClick={() => setEditingNotes(false)}
                      variant="ghost"
                      size="icon"
                    >
                      <Check className="w-4 h-4" />
                    </Button>
                    <Button
                      onClick={() => {
                        setNotes(document.notes);
                        setEditingNotes(false);
                      }}
                      size="icon"
                      variant="ghost"
                    >
                      <Undo2 className="w-4 h-4" />
                    </Button>
                  </div>
                ) : (
                  <Button
                    onClick={() => setEditingNotes(true)}
                    variant="ghost"
                    size="icon"
                  >
                    <Pencil className="w-4 h-4" />
                  </Button>
                )}
              </div>
              {editingNotes ? (
                <Textarea
                  className="mt-2"
                  onChange={(e) => setNotes(e.target.value)}
                />
              ) : (
                <div className="mt-2">{notes || "No notes added."}</div>
              )}
            </div>

            <div className="rounded-lg border border=[#e6e6e6] w-full p-4">
              <div className="flex justify-between items-center">
                <div className="flex gap-2 items-center">
                  <Waypoints className="w-4 h-4" />
                  <span className="font-medium">Threads</span>
                </div>

                <Button
                  onClick={() => setAddConnectionModalOpen(true)}
                  className="rounded-full items-center flex gap-2 shadow-none"
                  variant="outline"
                  size="sm"
                >
                  Add more
                  <PlusIcon className="w-3 h-3" />
                </Button>
              </div>
              <div className="flex mt-4 gap-2 flex-wrap">
                {connections.map((connection) => (
                  <ConnectionTag
                    type={connection.type}
                    key={connection.id}
                    name={connection.name}
                    id={connection.id}
                    confidence_score={connection.confidence_score || 100}
                    removeConnection={removeConnection}
                  />
                ))}
              </div>
            </div>
          </div>
          <DialogFooter className="mt-4">
            <Button
              onClick={() => saveConnections(connections)}
              disabled={savingDocument}
            >
              Save
            </Button>
          </DialogFooter>
        </DialogDarkBody>
      </DialogDarkContent>
      <AddConnectionDialog
        item={document}
        connections={connections}
        setConnections={setConnections}
        open={addConnectionModalOpen}
        onOpenChange={setAddConnectionModalOpen}
        saveConnections={(pendingConnections) => {
          setConnections(pendingConnections);
          setAddConnectionModalOpen(false);
        }}
      />
    </>
  );
}
