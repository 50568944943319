import { useState, useEffect } from "react";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { DialogFooter } from "@/components/ui/dialog";
import { Typeahead } from "@/components/ui/typeahead";
import { Icons } from "@/components/brand/icons";

import { ArrowRight } from "lucide-react";
import { institutions } from "@/config/financial-institutions";
import { createAccountViaStatement } from "../api";
import { toast } from "sonner";

export function AddAccountViaStatement({ setStep, setAccount }) {
  const [institutionOpen, setInstitutionOpen] = useState(false);
  const [institutionSearch, setInstitutionSearch] = useState("");
  const [financialInstitution, setFinancialInstitution] = useState();
  const [statement, setStatement] = useState();
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState();

  useEffect(() => {
    if (financialInstitution) {
      const institution = institutions.find(
        (i) => i.value === financialInstitution
      );
      if (institution) {
        setUrl(institution.url);
      }
    }
  }, [financialInstitution]);

  function handleFileUpload(event) {
    const temp_file = event.target.files[0];
    setStatement(temp_file);
  }

  const handleSubmit = async () => {
    if (!statement) return;

    const formData = new FormData();
    formData.append("statement", statement);
    formData.append("name", financialInstitution);
    formData.append("url", url);
    formData.append("account_type", "financial");

    try {
      setLoading(true);
      const res = await createAccountViaStatement(formData);
      setAccount(res.data);
      setStep(2);
    } catch (error) {
      toast.error("Error uploading statement. Please try again.");
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col gap-4 pb-4">
        <div className="space-y-2 flex flex-col gap-2">
          <Label htmlFor="name">Financial Institution</Label>
          <Typeahead
            options={institutions}
            emptyMessage="No results."
            placeholder="Input Institution"
            value={financialInstitution}
            onValueChange={(value) => {
              setFinancialInstitution(value.value);
            }}
          />
        </div>
        <div className="grid w-full items-center gap-2">
          <Label htmlFor="statement">Statement</Label>
          <Input
            onChange={handleFileUpload}
            className="w-full shadow-none"
            id="statement"
            type="file"
          />
        </div>
      </div>
      <DialogFooter>
        <Button
          onClick={handleSubmit}
          type="submit"
          size="icon"
          disabled={loading || !financialInstitution || !statement}
        >
          {loading ? (
            <Icons.spinner className="h-4 w-4 animate-spin" />
          ) : (
            <ArrowRight className="w-4 h-4" />
          )}
        </Button>
      </DialogFooter>
    </>
  );
}
