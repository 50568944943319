import { Dialog } from "@/components/ui/dialog";
import { CreateDocumentDialog } from "./create-document-dialog";
import { CreatePersonDialog } from "./create-person-dialog";
import { CreatePropertyDialog } from "./create-property-dialog";
import { CreateDigitalAccountDialog } from "./create-digital-account-dialog";
import { CreateLabelDialog } from "./create-label-dialog";
import { CreateFinancialAccountDialog } from "./create-financial-account-dialog";

export function ItemCreationDialog({
  open,
  onClose,
  item,
  context,
  reload = () => {},
  openPlaid,
  plaidReady,
  setProducts,
}) {
  return (
    <Dialog open={open} onOpenChange={onClose}>
      {item === "document" && (
        <CreateDocumentDialog
          context={context}
          reload={reload}
          onClose={onClose}
        />
      )}
      {item === "person" && (
        <CreatePersonDialog reload={reload} onClose={onClose} />
      )}
      {item === "property" && (
        <CreatePropertyDialog reload={reload} onClose={onClose} />
      )}
      {item === "financial_account" && (
        <CreateFinancialAccountDialog
          reload={reload}
          onClose={onClose}
          openPlaid={openPlaid}
          plaidReady={plaidReady}
          setProducts={setProducts}
        />
      )}
      {item === "digital_account" && (
        <CreateDigitalAccountDialog reload={reload} onClose={onClose} />
      )}
      {item === "label" && (
        <CreateLabelDialog reload={reload} onClose={onClose} />
      )}
    </Dialog>
  );
}
