import React, { useCallback, useState } from "react";
import { ReactSVG } from "react-svg";
import { useDropzone } from "react-dropzone";
import { uploadDocuments } from "../api";
import { toast } from "sonner";
import {
  DialogDarkContent,
  DialogDescription,
  DialogFooter,
  DialogDarkHeader,
  DialogTitle,
  DialogDarkBody,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Icons } from "@/components/brand/icons";
import { ArrowRight } from "lucide-react";
import { DocumentLinking } from "./document-linking";
import { updateDocument as updateDoc } from "../api";
import { Label } from "@/components/ui/label";

export function CreateDocumentDialog({ reload, onClose, context }) {
  const [step, setStep] = React.useState(1);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [notes, setNotes] = useState();

  const [document, setDocument] = React.useState(null);
  const onDrop = useCallback((acceptedFiles) => {
    uploadDocuments(acceptedFiles)
      .then((res) => {
        setDocument(res.data[0]);
        setStep(2);
      })
      .catch((err) => {
        toast.error("Failed to upload documents");
      });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  function updateDocument() {
    setLoading(true);
    setTimeout(() => {
      updateDoc({
        id: document.id,
        data: {
          name: name.length > 0 ? name : document?.name,
          notes,
        },
      })
        .then((res) => {
          setDocument(res.data);
          setStep(3);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 2000);
  }

  return (
    <>
      {step === 1 && (
        <DialogDarkContent className="sm:max-w-[425px]">
          <DialogDarkHeader>
            <DialogTitle>Add Document</DialogTitle>
            <DialogDescription className="sr-only">
              Upload a document to your vault.
            </DialogDescription>
          </DialogDarkHeader>
          <DialogDarkBody>
            <div className="grid gap-4 pb-4">
              <div
                {...getRootProps()}
                className={`mt-4 w-full border border-gray-200 rounded h-[126px] flex flex-col items-center justify-center cursor-pointer ${
                  isDragActive ? "bg-gray-200" : ""
                }`}
              >
                <input {...getInputProps()} multiple={false} />
                {isDragActive ? (
                  <>
                    <ReactSVG src={`/icons/upload.svg`} />
                    <p className="mt-2">
                      <b>Drop files here</b>
                    </p>
                  </>
                ) : (
                  <>
                    <ReactSVG src={`/icons/upload.svg`} />
                    <p className="mt-2">
                      <b>Click to upload</b> or drag and drop
                    </p>
                  </>
                )}
              </div>
            </div>
            <DialogFooter>
              <Button type="submit">Save changes</Button>
            </DialogFooter>
          </DialogDarkBody>
        </DialogDarkContent>
      )}
      {step === 2 && (
        <DialogDarkContent className="sm:max-w-[425px]">
          <DialogDarkHeader>
            <DialogTitle>Add details to file...</DialogTitle>
            <DialogDescription className="sr-only">
              Add details to your document.
            </DialogDescription>
          </DialogDarkHeader>
          <DialogDarkBody>
            <div className="flex flex-col gap-4 pt-2 pb-4">
              <div className="flex flex-col gap-2">
                <Label htmlFor="document_name">Document Name</Label>
                <Input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  id="document_name"
                  placeholder={document?.name}
                  className="col-span-3"
                />
              </div>

              <Textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Add any important details or notes on this file."
              />
            </div>
            <DialogFooter>
              <Button onClick={updateDocument} size="icon" disabled={loading}>
                {loading ? (
                  <Icons.spinner className="h-4 w-4 animate-spin" />
                ) : (
                  <ArrowRight className="w-4 h-4" />
                )}
              </Button>
            </DialogFooter>
          </DialogDarkBody>
        </DialogDarkContent>
      )}
      {step === 3 && (
        <DocumentLinking
          context={context}
          reload={reload}
          document={document}
          onClose={onClose}
        />
      )}
    </>
  );
}
