import { useState } from "react";
import {
  DialogDarkContent,
  DialogDarkBody,
  DialogDescription,
  DialogFooter,
  DialogDarkHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Waypoints, PlusIcon, Info } from "lucide-react";
import { AddConnectionDialog } from "./add-connection-dialog";
import { ConnectionTag } from "./connection-tag";
import { createPersonConnections } from "../api";

export function ConnectPerson({ onClose, person, reload }) {
  const [connections, setConnections] = useState([]);
  const [savingPerson, setSavingPerson] = useState(false);
  const [addConnectionModalOpen, setAddConnectionModalOpen] = useState(false);

  function removeConnection({ id, type }) {
    setConnections(
      connections.filter((c) => !(c.id === id && c.type === type))
    );
  }

  function saveConnections(connections) {
    setSavingPerson(true);
    createPersonConnections({
      personId: person.id,
      connections: connections.map(({ id, type }) => ({ id, type })),
    }).then((res) => {
      setSavingPerson(false);
      reload();
      onClose();
    });
  }

  return (
    <>
      <DialogDarkContent className="sm:max-w-[600px]">
        <DialogDarkHeader>
          <DialogTitle>Add threads to {person.name}</DialogTitle>
          <DialogDescription className="sr-only">
            Add person threads to your tapestry.
          </DialogDescription>
        </DialogDarkHeader>
        <DialogDarkBody>
          <div className="flex flex-col gap-6 items-center">
            <div className="rounded-lg border border=[#e6e6e6] w-full p-4">
              <div className="flex justify-between items-center">
                <div className="flex gap-2 items-center">
                  <Waypoints className="w-4 h-4" />
                  <span className="font-medium">Threads</span>
                </div>

                <Button
                  onClick={() => setAddConnectionModalOpen(true)}
                  className="rounded-full items-center flex gap-2 shadow-none"
                  variant="outline"
                  size="sm"
                >
                  Add more
                  <PlusIcon className="w-3 h-3" />
                </Button>
              </div>
              <div className="flex mt-4 gap-2 flex-wrap">
                {connections.length === 0 && (
                  <span className="text-muted-foreground text-sm">
                    No threads added. Threads are a way to easily organize and
                    thread together your digital life.
                  </span>
                )}
                {connections.map((connection) => (
                  <ConnectionTag
                    type={connection.type}
                    key={connection.id}
                    name={connection.name}
                    id={connection.id}
                    confidence_score={connection.confidence_score || 100}
                    removeConnection={removeConnection}
                  />
                ))}
              </div>
            </div>
          </div>
          <DialogFooter className="mt-4">
            <Button
              onClick={() => {
                saveConnections(connections);
              }}
              disabled={savingPerson}
            >
              Save
            </Button>
          </DialogFooter>
        </DialogDarkBody>
      </DialogDarkContent>
      <AddConnectionDialog
        item={person}
        connections={connections}
        setConnections={setConnections}
        open={addConnectionModalOpen}
        onOpenChange={setAddConnectionModalOpen}
        saveConnections={(pendingConnections) => {
          setConnections(pendingConnections);
          setAddConnectionModalOpen(false);
        }}
      />
    </>
  );
}
