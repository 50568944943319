export const institutions = [
  {
    value: "JPMorgan Chase & Co.",
    label: "JPMorgan Chase & Co.",
    url: "https://www.chase.com",
  },
  {
    value: "Bank of America",
    label: "Bank of America",
    url: "https://www.bankofamerica.com",
  },
  {
    value: "Citigroup",
    label: "Citigroup",
    url: "https://www.citigroup.com",
  },
  {
    value: "Wells Fargo",
    label: "Wells Fargo",
    url: "https://www.wellsfargo.com",
  },
  {
    value: "U.S. Bancorp",
    label: "U.S. Bancorp",
    url: "https://www.usbank.com",
  },
  {
    value: "Goldman Sachs",
    label: "Goldman Sachs",
    url: "https://www.goldmansachs.com",
  },
  {
    value: "Morgan Stanley",
    label: "Morgan Stanley",
    url: "https://www.morganstanley.com",
  },
  {
    value: "Truist Financial",
    label: "Truist Financial",
    url: "https://www.truist.com",
  },
  {
    value: "PNC Financial Services",
    label: "PNC Financial Services",
    url: "https://www.pnc.com",
  },
  {
    value: "TD Bank (TD Group US Holdings)",
    label: "TD Bank (TD Group US Holdings)",
    url: "https://www.td.com",
  },
  {
    value: "Capital One Financial",
    label: "Capital One Financial",
    url: "https://www.capitalone.com",
  },
  {
    value: "Charles Schwab",
    label: "Charles Schwab",
    url: "https://www.schwab.com",
  },
  {
    value: "HSBC North America",
    label: "HSBC North America",
    url: "https://www.hsbc.com",
  },
  {
    value: "American Express",
    label: "American Express",
    url: "https://www.americanexpress.com",
  },
  {
    value: "State Street Corporation",
    label: "State Street Corporation",
    url: "https://www.statestreet.com",
  },
  {
    value: "Citizens Financial Group",
    label: "Citizens Financial Group",
    url: "https://www.citizensbank.com",
  },
  {
    value: "Fifth Third Bancorp",
    label: "Fifth Third Bancorp",
    url: "https://www.53.com",
  },
  {
    value: "KeyCorp",
    label: "KeyCorp",
    url: "https://www.key.com",
  },
  {
    value: "Ally Financial",
    label: "Ally Financial",
    url: "https://www.ally.com",
  },
  {
    value: "BMO Harris Bank",
    label: "BMO Harris Bank",
    url: "https://www.bmoharris.com",
  },
  {
    value: "Huntington Bancshares",
    label: "Huntington Bancshares",
    url: "https://www.huntington.com",
  },
  {
    value: "M&T Bank",
    label: "M&T Bank",
    url: "https://www.mtb.com",
  },
  {
    value: "Northern Trust",
    label: "Northern Trust",
    url: "https://www.northerntrust.com",
  },
  {
    value: "Regions Financial Corporation",
    label: "Regions Financial Corporation",
    url: "https://www.regions.com",
  },
  {
    value: "First Republic Bank",
    label: "First Republic Bank",
    url: "https://www.firstrepublic.com",
  },
  {
    value: "Synchrony Financial",
    label: "Synchrony Financial",
    url: "https://www.synchrony.com",
  },
  {
    value: "SVB Financial Group",
    label: "SVB Financial Group",
    url: "https://www.svb.com",
  },
  {
    value: "CIBC Bank USA",
    label: "CIBC Bank USA",
    url: "https://us.cibc.com",
  },
  {
    value: "New York Community Bancorp",
    label: "New York Community Bancorp",
    url: "https://www.mynycb.com",
  },
  {
    value: "Zions Bancorporation",
    label: "Zions Bancorporation",
    url: "https://www.zionsbancorporation.com",
  },
  {
    value: "Comerica",
    label: "Comerica",
    url: "https://www.comerica.com",
  },
  {
    value: "Popular, Inc.",
    label: "Popular, Inc.",
    url: "https://www.popular.com",
  },
  {
    value: "First Citizens BancShares",
    label: "First Citizens BancShares",
    url: "https://www.firstcitizens.com",
  },
  {
    value: "Raymond James Financial",
    label: "Raymond James Financial",
    url: "https://www.raymondjames.com",
  },
  {
    value: "Pinnacle Financial Partners",
    label: "Pinnacle Financial Partners",
    url: "https://www.pnfp.com",
  },
  {
    value: "Valley National Bancorp",
    label: "Valley National Bancorp",
    url: "https://www.valley.com",
  },
  {
    value: "East West Bancorp",
    label: "East West Bancorp",
    url: "https://www.eastwestbank.com",
  },
  {
    value: "Bank OZK",
    label: "Bank OZK",
    url: "https://www.ozk.com",
  },
  {
    value: "First Horizon Corporation",
    label: "First Horizon Corporation",
    url: "https://www.firsthorizon.com",
  },
  {
    value: "Associated Banc-Corp",
    label: "Associated Banc-Corp",
    url: "https://www.associatedbank.com",
  },
  {
    value: "FNB Corporation",
    label: "FNB Corporation",
    url: "https://www.fnb-online.com",
  },
  {
    value: "UMB Financial Corporation",
    label: "UMB Financial Corporation",
    url: "https://www.umb.com",
  },
  {
    value: "Webster Financial Corporation",
    label: "Webster Financial Corporation",
    url: "https://www.websterbank.com",
  },
  {
    value: "Old National Bancorp",
    label: "Old National Bancorp",
    url: "https://www.oldnational.com",
  },
  {
    value: "Wintrust Financial Corporation",
    label: "Wintrust Financial Corporation",
    url: "https://www.wintrust.com",
  },
  {
    value: "South State Corporation",
    label: "South State Corporation",
    url: "https://www.southstatebank.com",
  },
  {
    value: "Hancock Whitney",
    label: "Hancock Whitney",
    url: "https://www.hancockwhitney.com",
  },
  {
    value: "Western Alliance Bancorporation",
    label: "Western Alliance Bancorporation",
    url: "https://www.westernalliancebancorporation.com",
  },
  {
    value: "First Midwest Bancorp",
    label: "First Midwest Bancorp",
    url: "https://www.firstmidwest.com",
  },
  {
    value: "BOK Financial",
    label: "BOK Financial",
    url: "https://www.bokfinancial.com",
  },
  {
    value: "United Bankshares",
    label: "United Bankshares",
    url: "https://www.ubsi-inc.com",
  },
  {
    value: "Prosperity Bancshares",
    label: "Prosperity Bancshares",
    url: "https://www.prosperitybankusa.com",
  },
  {
    value: "Pacific Premier Bancorp",
    label: "Pacific Premier Bancorp",
    url: "https://www.ppbi.com",
  },
  {
    value: "Texas Capital Bancshares",
    label: "Texas Capital Bancshares",
    url: "https://www.texascapitalbank.com",
  },
  {
    value: "Atlantic Union Bank",
    label: "Atlantic Union Bank",
    url: "https://www.atlanticunionbank.com",
  },
  {
    value: "Cathay General Bancorp",
    label: "Cathay General Bancorp",
    url: "https://www.cathaybank.com",
  },
  {
    value: "Banner Corporation",
    label: "Banner Corporation",
    url: "https://www.bannerbank.com",
  },
  {
    value: "Renasant Corporation",
    label: "Renasant Corporation",
    url: "https://www.renasantbank.com",
  },
  {
    value: "Hope Bancorp",
    label: "Hope Bancorp",
    url: "https://www.bankofhope.com",
  },
  {
    value: "Glacier Bancorp",
    label: "Glacier Bancorp",
    url: "https://www.glacierbancorp.com",
  },
  {
    value: "Vanguard",
    label: "Vanguard",
    url: "https://www.vanguard.com"
  },
  {
    value: "Fidelity",
    label: "Fidelity",
    url: "https://www.fidelity.com"
  },
  {
    value: "Robinhood",
    label: "Robinhood",
    url: "https://robinhood.com"
  }
];
